<template>
  <v-form  ref="addDocument" v-model="valid" :disabled="loading">
    <label>{{ $t('companiesDetails.name') }}</label>
    <v-text-field v-model="description" outlined dense :rules="required" />
    <v-radio-group v-if="type !== 4" v-model="validOptions" class="w-50">
      <v-radio :value="0">
        <template v-slot:label>
          <span>{{ $t('companiesDetails.indefinitely') }}</span>
        </template>
      </v-radio>
      <v-radio :value="1">
        <template v-slot:label>
          <v-menu
            v-model="validToMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="validTo"
                label="data"
                append-icon="$calendar"
                readonly
                dense
                outlined
                single-line
                hide-details
                :disabled="validIndefinitely() || validityNotApplicable()"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="validTo"
              :first-day-of-week="1"
              @input="validToMenu = false"
            ></v-date-picker>
          </v-menu>
        </template>
      </v-radio>
      <v-radio v-if="type === 4" :value="2">
        <template v-slot:label>
          <span>{{ $t('companiesDetails.notApplicable') }}</span>
        </template>
      </v-radio>
    </v-radio-group>
    <p v-if="!isValid" class="red--text">{{ $t('companiesDetails.required') }}</p>
    <div class="d-flex flex-column">
      <UploadFileButton
        :label="$t('companiesDetails.addFiles')"
        :loading="loading"
        @input="uploadFiles($event)"
      />
    </div>
    <div class="d-flex">
    <v-progress-linear v-if="loading" v-model="uploadValue" color="green"></v-progress-linear>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined @click="save" :loading="loading" :disabled="loading">
        {{ $t('companiesDetails.save') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import UploadFileButton from '@/components/forms/UploadFileButton'
export default {
  name: 'NewDocument',
  props: ['loading', 'type', 'uploadValue'],
  components: {
    UploadFileButton
  },
  data () {
    return {
      valid: true,
      isValid: true,
      validTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      files: [],
      validToMenu: false,
      validOptions: 0,
      notificationMothBefore: false,
      notificationWeekBefore: false,
      notificationDayBefore: false,
      description: '',
      required: [(v) => !!v.trim() || this.$t('validation.fieldRequired')]
    }
  },
  watch: {
    validOptions () {
      if (this.validIndefinitely() || this.validityNotApplicable()) {
        this.notificationMothBefore = false
        this.notificationWeekBefore = false
        this.notificationDayBefore = false
      }
    }
  },
  methods: {
    save () {
      this.$refs.addDocument.validate()
      this.isValid = this.files.length > 0

      if (!this.valid || !this.isValid) {
        return
      }

      this.$emit('submit', {
        description: this.description,
        validTo: this.validTo,
        type: this.type,
        validIndefinitely: this.validIndefinitely(),
        validityNotApplicable: this.validityNotApplicable(),
        notificationMothBefore: this.notificationMothBefore,
        notificationWeekBefore: this.notificationWeekBefore,
        notificationDayBefore: this.notificationDayBefore,
        files: this.files
      })
      this.$refs.addDocument.resetValidation()
    },
    uploadFiles (event) {
      this.isValid = true
      this.files = event
    },
    addDocument (e) {
      this.files = e.target.files
    },
    validIndefinitely () {
      return this.validOptions === 0
    },
    validityNotApplicable () {
      return this.validOptions === 2
    }
  }
}
</script>
