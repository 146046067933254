<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $t('rejectCompany.title') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="rejectForm" v-model="valid">
            <label class="mt-8">{{ $t('rejectCompany.reason') }}</label>
            <v-textarea
              v-model="reason"
              :rules="required"
              :disabled="loading"
              auto-grow
              outlined
              rows="8"
              row-height="15"
            ></v-textarea>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secodary"
          outlined
          class="mr-2"
          :disabled="loading"
          @click="close"
        >
          {{ $t("common.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="submit"
        >
          Odrzuć
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'RejectCompany',
  props: ['dialog', 'loading'],
  data () {
    return {
      valid: true,
      reason: '',
      required: [(v) => !!v.trim() || this.$t('validation.fieldRequired')]
    }
  },
  watch: {
    dialog () {
      this.reason = ''
    }
  },
  methods: {
    close () {
      this.$refs.rejectForm.resetValidation()
      this.$emit('close')
    },
    submit () {
      this.$refs.rejectForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('submit', this.reason)
      this.$refs.rejectForm.resetValidation()
    }
  }
}
</script>
