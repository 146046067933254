<template>
  <div>
    <v-row no-gutters>
      <h3>
        {{ $t("issueRegister.checklist") }}
      </h3>
    </v-row>
    <v-row
      v-for="item in checklist"
      :key="item.id"
      class="mb-2"
      no-gutters
    >
      <v-col>
        <v-checkbox
          v-model="item.checked"
          class="ma-0"
          hide-details
          @change="checkItem(item.dictionaryId, item.checked)"
        >
          <template v-slot:label>
            <div class="text-subtitle-2">
              {{ item.name }}
            </div>
          </template>
        </v-checkbox>
        <v-row v-if="item.checkedBy" class="ml-8 text-caption" no-gutters>
          <v-col>
            <v-row no-gutters>
              {{ item.checkedBy.name }} {{ item.checkedBy.surname }}
            </v-row>
            <v-row no-gutters>
              {{ item.checkDate | dateFormat("DD.MM.YYYY HH:mm") }}
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Service from '@/services'

export default {
  name: 'Checklist',
  props: {
    issueId: {
      type: String,
      required: true
    },
    checklist: {
      type: Array,
      required: true
    },
    update: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    async update () {
      await this.getCheckList()
    }
  },
  methods: {
    async checkItem (dictionaryId, isChecked) {
      await Service.post('/Issue/MarkCheckList', {
        issueId: this.issueId,
        dictionaryId: dictionaryId,
        checked: isChecked
      })

      await this.getCheckList()
    },
    async getCheckList () {
      const { data } = await Service.get('/Issue/GetCheckList', {
        issueId: this.issueId
      })

      this.$emit('checklist-updated', data)
    }

  }
}
</script>
